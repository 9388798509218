var hamburg = document.querySelector('.hamburger'),
    menu = document.querySelector('.menu-list');

hamburg.addEventListener('click', function() {
  this.classList.toggle('hamburger-is-open');
  menu.classList.toggle('menu-list--open');
}); 



$(function(){
  $("a[href^='#']").click(function(){
          var _href = $(this).attr("href");
          $("html, body").animate({scrollTop: $(_href).offset().top+"px"});
          return false;
  });
}); 




$(document).ready(function($) {
	$('.delivery-link').click(function() {
		$('.popup-fade-2').fadeIn();
		return false;
	});	
    
	$('.popup-close').click(function() {
		$(this).parents('.popup-fade-2').fadeOut();
		return false;
	});		

	$(document).keydown(function(e) {
		if (e.keyCode === 27) {
			e.stopPropagation();
			$('.popup-fade-2').fadeOut();
		}
	});
    
    $('.popup-fade-2').click(function(e) {
		if ($(e.target).closest('.popup-delivery').length == 0) {
			$(this).fadeOut();					
		}
	});	
});


$(document).ready(function($) {
	$('.return-link').click(function() {
		$('.popup-fade-3').fadeIn();
		return false;
	});	
    
	$('.popup-close').click(function() {
		$(this).parents('.popup-fade-3').fadeOut();
		return false;
	});		

	$(document).keydown(function(e) {
		if (e.keyCode === 27) {
			e.stopPropagation();
			$('.popup-fade-3').fadeOut();
		}
	});
    
    $('.popup-fade-3').click(function(e) {
		if ($(e.target).closest('.popup-return').length == 0) {
			$(this).fadeOut();					
		}
	});	
});


document.addEventListener('DOMContentLoaded', function() {

  var Isoelem = document.querySelector('.products-wrapper');
  var iso = new Isotope( Isoelem, {
    // options
    itemSelector: '.product-obj', // use a separate class for itemSelector, other than .col-
    percentPosition: true,
    layoutMode: 'masonry',
    masonry: {
      columnWidth: '.grid-sizer'
    }
  });
  
  
  var all = document.querySelector('#onwall');
  all.addEventListener('click', function() {
    iso.arrange({ 
      filter: '.board-onwall',
    })
  });
  
  
  var all = document.querySelector('#mobile');
  all.addEventListener('click', function() {
    iso.arrange({ 
      filter: '.board-mobile',
    })
  });
  
  var all = document.querySelector('#reset-filter');
  all.addEventListener('click', function() {
    iso.arrange({ 
      filter: '*',
    })
  });
});


var tags = document.querySelector('.sorting-block__vars'),
    tagsList = document.querySelectorAll('.sorting-tag');

 tags.onclick = function(event) {
  var target = event.target;
  for (var i = 0; i < tagsList.length; ++i) {
    var item = tagsList[i]; 
    item.classList.remove('active-tag');
  }
  if (event.target.classList.contains('sorting-tag')) {
  target.classList.add('active-tag');
};
 };




function addLink(e) {
	e.preventDefault();
	var pagelink = '\nИсточник: ' + document.location.href,
	copytext =  window.getSelection() + pagelink;
	clipdata = e.clipboardData || window.clipboardData;
	if (clipdata) {
			clipdata.setData('Text', copytext);
	}
}
document.addEventListener('copy', addLink);


